import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, VSection } from "components/common"
import { PrimaryNav, MobileNav } from "src/components/navigation"
import { useContentfulSection, useMeta } from "utils"

const ErrorPage = ({ data: { error } }) => {
  const {
    hero_400: { title, subtitle },
  } = useContentfulSection(error.sections)

  const meta = useMeta(error)

  return (
    <>
      <MobileNav />
      <PrimaryNav />
      <Wrap>
        <Layout hideFooter>
          <Seo {...meta} />

          <VSection
            className="messageBox"
            titleAs="h1"
            align="left"
            title={title}
            subtitle={subtitle}
          />
        </Layout>
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--section-background-color);

  .messageBox {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    .v_tgContainer {
      --tg-max-width: 800px;
      .subtitle {
        white-space: break-spaces;

        a {
          color: var(--water);
        }
      }
    }
  }
`

export const query = graphql`
  {
    error: contentfulPage(pageId: { eq: "400" }) {
      ...Page
    }
  }
`

export default ErrorPage
